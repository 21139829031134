import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faCircle } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import './Header.css';

const Header = ({ sensorData, onSensorUpdate }) => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState('');
  const [error, setError] = useState(null);

  if (!sensorData) {
    return <div className="loading">Loading header...</div>;
  }

  const { sensor_id, metadata, latest_data_timestamp } = sensorData;
  const sensorName = metadata.name ? metadata.name.value : 'Unnamed Sensor';
  const lastUpdate = latest_data_timestamp ? new Date(latest_data_timestamp).toLocaleString() : 'N/A';
  const isActive = latest_data_timestamp && (new Date() - new Date(latest_data_timestamp)) < 43200000; // 12 hours

  const handleEditClick = () => {
    setIsEditing(true);
    setNewName(sensorName);
    setError(null);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put(`/sensors/update_name/${sensor_id}/`, { name: newName });
      setIsEditing(false);
      setError(null);
      // Update the sensor data in the parent component
      onSensorUpdate({ ...sensorData, metadata: { ...sensorData.metadata, name: { value: newName } } });
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred while updating the sensor name.');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setError(null);
  };

  return (
    <header className="sensor-header">
      <div className="header-content">
        <button onClick={() => navigate('/map')} className="icon-button back-button">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="sensor-info">
          {isEditing ? (
            <form onSubmit={handleSubmit} className="edit-name-form">
              <input
                type="text"
                value={newName}
                onChange={handleNameChange}
                className="edit-name-input"
                autoFocus
              />
              <button type="submit" className="submit-button">Submit</button>
              <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
              {error && <div className="error-message">{error}</div>}
            </form>
          ) : (
            <>
              <h1 className="sensor-name">{sensorName}</h1>
              <div className="sensor-details">
                <span className="sensor-id">ID: {sensor_id}</span>
                <span className="separator">|</span>
                <span className="last-update">Updated: {lastUpdate}</span>
                <span className="separator">|</span>
                <span className={`status ${isActive ? 'active' : 'inactive'}`}>
                  <FontAwesomeIcon icon={faCircle} /> {isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </>
          )}
        </div>
        {!isEditing && (
          <button onClick={handleEditClick} className="icon-button edit-button">
            <FontAwesomeIcon icon={faEdit} />
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
