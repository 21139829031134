import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBatteryThreeQuarters, faSignal, faThermometerHalf, faArrowRight, faArrowUp, faArrowDown, faClock, faRuler, faMapMarkerAlt, faCompass, faTachometerAlt, faGlobe, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { setGpsTrackerPublicStatus } from '../../../gps-api';
import './GPSTrackerPanel.css';

const GPSTrackerPanel = ({ tracker, onClose, dataTypeDefinitions, onTrackerUpdate }) => {
  const { name, gps_id, tracker_type, metadata, latest_health_data, last_10_locations } = tracker;
  const [isPublic, setIsPublic] = useState(metadata.is_public);

  useEffect(() => {
    // Ensure the panel is visible on mobile
    document.querySelector('.gtp-panel').style.transform = 'translateX(0)';
  }, []);

  const latestLocation = last_10_locations && last_10_locations.length > 0 ? last_10_locations[0] : null;

  const trackerTypeLabel = dataTypeDefinitions[`${tracker_type}_tracker_metadata`]?.label || tracker_type;

  const renderMetadata = () => {
    const specificMetadata = tracker_type === 'vehicle' ? metadata : metadata;
    const relevantKeys = tracker_type === 'vehicle' 
      ? ['vehicle_type', 'make', 'model', 'year', 'registration_number', 'color', 'capacity', 'fuel_type'] 
      : ['animal_type', 'species', 'weight', 'size', 'classification', 'age', 'breed', 'gender', 'date_born'];

    return relevantKeys.map(key => {
      if (specificMetadata[key]) {
        const label = dataTypeDefinitions[`${tracker_type}_tracker_metadata`]?.[key]?.label || key;
        const unit = dataTypeDefinitions[`${tracker_type}_tracker_metadata`]?.[key]?.unit ? ` (${dataTypeDefinitions[`${tracker_type}_tracker_metadata`][key].unit})` : '';
        return (
          <p key={key}>
            <strong>{label}{unit}:</strong> {specificMetadata[key]}
          </p>
        );
      }
      return null;
    });
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return (distance * 0.621371).toFixed(2); // Convert km to miles
  };

  const calculateElevationChange = (alt1, alt2) => {
    return (alt2 - alt1).toFixed(2);
  };

  const renderLocationHistory = () => {
    return (
      <div className="gtp-location-history">
        <h3>Recent Locations</h3>
        <div className="gtp-location-timeline">
          {last_10_locations.map((location, index, arr) => {
            const prevLocation = arr[index + 1];
            const distance = prevLocation 
              ? calculateDistance(location.latitude, location.longitude, prevLocation.latitude, prevLocation.longitude)
              : null;
            const elevationChange = prevLocation
              ? calculateElevationChange(prevLocation.altitude, location.altitude)
              : null;
            
            return (
              <div key={index} className="gtp-location-item">
                <div className="gtp-location-dot"></div>
                <div className="gtp-location-info">
                  <div className="gtp-location-header">
                    <span className="gtp-location-number">{index + 1}</span>
                    <span className="gtp-location-time">
                      <FontAwesomeIcon icon={faClock} />
                      {new Date(location.timestamp).toLocaleString()}
                    </span>
                  </div>
                  {distance && (
                    <div className="gtp-location-distance">
                      <FontAwesomeIcon icon={faRuler} />
                      <span>{distance} miles</span>
                    </div>
                  )}
                  {elevationChange && (
                    <div className="gtp-location-elevation">
                      <FontAwesomeIcon icon={elevationChange > 0 ? faArrowUp : faArrowDown} />
                      <span>{Math.abs(elevationChange)} m {elevationChange > 0 ? 'gain' : 'loss'}</span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderLatestInfo = () => {
    if (!latestLocation) {
      return (
        <div className="gtp-latest-info">
          <h3>Latest Information</h3>
          <p>No location data available</p>
        </div>
      );
    }

    return (
      <div className="gtp-latest-info">
        <h3>Latest Information</h3>
        <div className="gtp-latest-info-grid">
          <div className="gtp-info-item">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span>
              <strong>Location:</strong> {latestLocation.latitude?.toFixed(6) || 'N/A'}, {latestLocation.longitude?.toFixed(6) || 'N/A'}
            </span>
          </div>
          <div className="gtp-info-item">
            <FontAwesomeIcon icon={faArrowUp} />
            <span>
              <strong>Altitude:</strong> {latestLocation.altitude?.toFixed(2) || 'N/A'} m
            </span>
          </div>
          <div className="gtp-info-item">
            <FontAwesomeIcon icon={faTachometerAlt} />
            <span>
              <strong>Velocity:</strong> {latestLocation.velocity?.toFixed(2) || 'N/A'} m/s
            </span>
          </div>
          <div className="gtp-info-item">
            <FontAwesomeIcon icon={faCompass} />
            <span>
              <strong>Heading:</strong> {latestLocation.heading?.toFixed(2) || 'N/A'}°
            </span>
          </div>
          <div className="gtp-info-item">
            <FontAwesomeIcon icon={faClock} />
            <span>
              <strong>Last Updated:</strong> {latestLocation.timestamp ? new Date(latestLocation.timestamp).toLocaleString() : 'N/A'}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const handlePublicToggle = async () => {
    try {
      const newPublicStatus = !isPublic;
      console.log(`Toggling public status for GPS ID ${gps_id} to ${newPublicStatus}`);
      const response = await setGpsTrackerPublicStatus(gps_id, newPublicStatus);
      console.log('API Response:', response);
      if (response && response.is_public === newPublicStatus) {
        setIsPublic(newPublicStatus);
        onTrackerUpdate({ ...tracker, metadata: { ...metadata, is_public: newPublicStatus } });
      } else {
        console.error('Failed to update public status. Response:', response);
      }
    } catch (error) {
      console.error('Error toggling public status:', error);
      if (error.response) {
        console.error('Response error data:', error.response.data);
      } else {
        console.error('Unexpected error:', error.message);
      }
    }
  };

  return (
    <div className="gtp-panel">
      <button className="gtp-close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <h2>{name || gps_id}</h2>
      <p className="gtp-tracker-type">{trackerTypeLabel}</p>
      <div className="gtp-health-data">
        <div className="gtp-health-item">
          <FontAwesomeIcon icon={faBatteryThreeQuarters} />
          <span>{latest_health_data?.battery_life?.toFixed(1) || 'N/A'}%</span>
        </div>
        <div className="gtp-health-item">
          <FontAwesomeIcon icon={faSignal} />
          <span>{latest_health_data?.signal_strength || 'N/A'} dBm</span>
        </div>
        <div className="gtp-health-item">
          <FontAwesomeIcon icon={faThermometerHalf} />
          <span>{latest_health_data?.temperature?.toFixed(1) || 'N/A'}°C</span>
        </div>
      </div>
      {renderLatestInfo()}
      <div className="gtp-metadata">
        <h3>Metadata</h3>
        {renderMetadata()}
      </div>
      {renderLocationHistory()}
      <div className="gtp-public-toggle-heading">
        This makes the sensor public to everyone
      </div>
      <div className="gtp-public-toggle">
        <span>{isPublic ? 'Public' : 'Private'}</span>
        <button className="gtp-toggle-button" onClick={handlePublicToggle}>
          <FontAwesomeIcon icon={isPublic ? faLockOpen : faLock} />
          {isPublic ? 'Make Private' : 'Make Public'}
        </button>
      </div>
    </div>
  );
};

export default GPSTrackerPanel;
