/*
SensorDataPage.js

This script shows detailed information regarding a specific sensor.
*/


// ==================================
// ============ IMPORTS =============
// ==================================
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSensorInfo, fetch7DayTrend, fetchSensorMeasurements } from '../../api';
import Header from '../../components/sensor-components/Header/Header';
import MapAndLiveData from '../../components/sensor-components/MapAndLiveData/MapAndLiveData';
import ChartAndGraphing from '../../components/sensor-components/ChartAndGraphing/ChartAndGraphing';
import ExportData from '../../components/sensor-components/ExportData/ExportData';
import GeneratePDFReport from '../../components/sensor-components/GeneratePDFReport/GeneratePDFReport';
import './SensorDataPage.css';
import NotesSensorInfo from '../../components/sensor-components/NotesSensorInfo/NotesSensorInfo';
import SharingAndAdminContainer from '../../components/sensor-components/SharingAndAdminContainer/SharingAndAdminContainer';


const SensorDataPage = () => {
  const { sensorId } = useParams();
  const [sensorData, setSensorData] = useState(null);
  const [trendData, setTrendData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await fetchSensorInfo(sensorId);
        console.log('Fetched sensor data:', data);
        setSensorData(data);
      } catch (error) {
        console.error('Error fetching sensor data:', error);
        setError('Failed to fetch sensor data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sensorId]);

  const fetchChartData = async (period) => {
    try {
      const data = await fetchSensorMeasurements(sensorId, period);
      setChartData(data);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  if (loading) {
    return <div className="loading">Loading sensor data...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const dataTypes = sensorData ? Object.keys(sensorData.latest_data) : [];

  const handleSensorUpdate = (updatedSensorData) => {
    setSensorData(updatedSensorData);
  };

  const getDataTypes = () => {
    if (sensorData && sensorData.latest_data) {
      return Object.keys(sensorData.latest_data).map(key => ({
        key,
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')
      }));
    }
    return [];
  };

  return (
    <div className="sensor-data-page">
      <Header sensorData={sensorData} onSensorUpdate={handleSensorUpdate} />
      <MapAndLiveData sensorData={sensorData} onSensorUpdate={handleSensorUpdate} />
      <ChartAndGraphing sensorId={sensorId} fetchChartData={fetchSensorMeasurements} />
      <NotesSensorInfo sensorData={sensorData} onSensorUpdate={handleSensorUpdate} />
      <ExportData sensorId={sensorId} dataTypes={getDataTypes()} />
      <GeneratePDFReport sensorId={sensorId} />
      <SharingAndAdminContainer sensorData={sensorData} onSensorUpdate={handleSensorUpdate} />
    </div>
  );
};

export default SensorDataPage;
