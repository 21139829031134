import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, ZoomControl } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faBatteryThreeQuarters, faClock, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Icon } from 'leaflet';
import './MapAndLiveData.css';
import api from '../../../api';
import MapIcon from '../../../assets/MapIcon.png';

// Define icons for active and inactive states
const iconWidth = 20; // You can adjust this base width as needed
const iconHeight = Math.round((559 / 300) * iconWidth); // Calculating height to maintain aspect ratio

const customIcon = new Icon({
  iconUrl: MapIcon,
  iconSize: [iconWidth, iconHeight],
  iconAnchor: [iconWidth / 2, iconHeight] // Anchor point at the bottom center of the icon
});

const MapAndLiveData = ({ sensorData, onSensorUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newLocationNotes, setNewLocationNotes] = useState('');
  const [error, setError] = useState(null);

  if (!sensorData) {
    return <div className="loading">Loading map and live data...</div>;
  }

  const { gps_location, latest_data, latest_health_data, latest_data_timestamp, latest_health_data_timestamp, metadata } = sensorData;

  // Function to get the correct GPS coordinates
  const getGPSCoordinates = () => {
    if (gps_location && gps_location.latitude && gps_location.longitude) {
      return { lat: gps_location.latitude, lon: gps_location.longitude };
    } else if (latest_data && latest_data.gps_location && latest_data.gps_location.value) {
      return { 
        lat: latest_data.gps_location.value.latitude, 
        lon: latest_data.gps_location.value.longitude 
      };
    }
    return null;
  };

  const coordinates = getGPSCoordinates();
  const isValidLocation = coordinates && typeof coordinates.lat === 'number' && typeof coordinates.lon === 'number';

  const getTimeDifference = (timestamp) => {
    const diff = (new Date() - new Date(timestamp)) / 1000;
    if (diff < 60) return `${Math.round(diff)}s ago`;
    if (diff < 3600) return `${Math.round(diff / 60)}m ago`;
    if (diff < 86400) return `${Math.round(diff / 3600)}h ago`;
    return `${Math.round(diff / 86400)}d ago`;
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setNewLocationNotes(metadata.location_notes ? metadata.location_notes.value : '');
    setError(null);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setError(null);
  };

  const handleSubmit = async () => {
    try {
      const response = await api.put(`/sensors/update_location/${sensorData.sensor_id}/`, { location: newLocationNotes });
      onSensorUpdate({
        ...sensorData,
        metadata: {
          ...sensorData.metadata,
          location_notes: { value: response.data.new_location }
        }
      });
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update location notes');
    }
  };

  return (
    <div className="map-and-live-data-container">
      <div className="map-container">
        {isValidLocation ? (
          <MapContainer center={[coordinates.lat, coordinates.lon]} zoom={13} className="map" zoomControl={false}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[coordinates.lat, coordinates.lon]} icon={customIcon} />
            <ZoomControl position="topleft" />
          </MapContainer>
        ) : (
          <div className="map-placeholder">GPS location not available</div>
        )}
        <div className="map-info">
          {isEditing ? (
            <div className="edit-location-notes">
              <textarea
                value={newLocationNotes}
                onChange={(e) => setNewLocationNotes(e.target.value)}
                placeholder="Enter new location notes"
              />
              <div className="button-group">
                <button onClick={handleSubmit} className="submit-button">Submit</button>
                <button onClick={handleCancel} className="cancel-button">Cancel</button>
              </div>
              {error && <div className="error-message">{error}</div>}
            </div>
          ) : (
            <>
              <p>{metadata.location_notes ? metadata.location_notes.value : 'No location description available.'}</p>
              <button onClick={handleEditClick} className="update-location-btn">
                <FontAwesomeIcon icon={faEdit} /> Update
              </button>
            </>
          )}
        </div>
      </div>

      <div className="data-container">
        <div className="data-section measurement-section">
          <h2 className="section-title">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            Live Measurements
            <span className="update-time">
              <FontAwesomeIcon icon={faClock} /> {getTimeDifference(latest_data_timestamp)}
            </span>
          </h2>
          <div className="data-grid measurement-grid">
            {Object.entries(latest_data).map(([key, data]) => (
              key !== 'gps_location' && (
                <div key={key} className="data-item measurement-item">
                  <span className="data-value">{data.value}</span>
                  <span className="data-unit">{data.unit}</span>
                  <span className="data-label">{data.label}</span>
                </div>
              )
            ))}
          </div>
        </div>

        {Object.keys(latest_health_data).length > 0 && (
          <div className="data-section health-section">
            <h2 className="section-title">
              <FontAwesomeIcon icon={faBatteryThreeQuarters} />
              Sensor Health
              <span className="update-time">
                <FontAwesomeIcon icon={faClock} /> {latest_health_data_timestamp ? getTimeDifference(latest_health_data_timestamp) : 'N/A'}
              </span>
            </h2>
            <div className="data-grid health-grid">
              {Object.entries(latest_health_data).map(([key, data]) => (
                <div key={key} className="data-item health-item">
                  <span className="data-label">{data.label}</span>
                  <span className="data-value">{data.value}</span>
                  <span className="data-unit">{data.unit}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapAndLiveData;
