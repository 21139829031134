import React from 'react';
import { Marker, Polyline, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import './GPSTrackerMarker.css';

const GPSTrackerMarker = ({ tracker, onClick, dataTypeDefinitions, isSelected }) => {
  if (!tracker || !tracker.last_10_locations || tracker.last_10_locations.length === 0) {
    console.error('Invalid tracker data:', tracker);
    return null;
  }

  const latestLocation = tracker.last_10_locations[0];
  const { latitude, longitude } = latestLocation;

  const customIcon = L.divIcon({
    className: 'gps-tracker-marker',
    html: `<div class="marker-icon ${tracker.tracker_type}"></div>`,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const positions = tracker.last_10_locations.map(loc => [loc.latitude, loc.longitude]);

  const handleClick = () => {
    onClick(tracker);
  };

  const trackerTypeLabel = dataTypeDefinitions[`${tracker.tracker_type}_tracker_metadata`]?.label || tracker.tracker_type;

  return (
    <>
      <Marker
        position={[latitude, longitude]}
        icon={customIcon}
        eventHandlers={{
          click: handleClick,
        }}
      >
        <Tooltip direction="top" offset={[0, -20]} opacity={1}>
          <div>
            <strong>{tracker.name || 'Unnamed Tracker'}</strong>
            <br />
            Type: {trackerTypeLabel}
          </div>
        </Tooltip>
      </Marker>
      <Polyline positions={positions} color="#3388ff" weight={3} opacity={0.7} />
      {isSelected && tracker.last_10_locations.slice(1).map((location, index) => (
        <Marker
          key={index}
          position={[location.latitude, location.longitude]}
          icon={L.divIcon({
            className: 'custom-history-icon',
            html: `<div class="history-marker">${index + 2}</div>`,
            iconSize: [30, 30],
            iconAnchor: [15, 30]
          })}
        />
      ))}
    </>
  );
};

export default GPSTrackerMarker;
