import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import './ExportData.css';

const ExportData = ({ sensorId, dataTypes }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [exportFormat, setExportFormat] = useState('csv');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (dataTypes && Array.isArray(dataTypes)) {
      setSelectedData(dataTypes.map(type => type.key));
    } else {
      console.error('ExportData: Invalid or missing dataTypes prop', dataTypes);
      setError('Unable to load data types. Please try again later.');
    }
  }, [dataTypes]);

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}${(d.getMonth() + 1).toString().padStart(2, '0')}${d.getFullYear()}`;
  };

  const handleExport = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      const dateRange = `${formattedStartDate}-${formattedEndDate}`;

      const response = await api.post(`/sensors/export_data/${sensorId}/`, {
        data_types: selectedData,
        date_range: dateRange,
        export_format: exportFormat
      }, {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${sensorId}-export-${exportFormat}-${formatDate(new Date())}.${exportFormat}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSuccessMessage('Data exported successfully!');
    } catch (err) {
      console.error('Error in handleExport:', err);
      if (err.response && err.response.data) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorMessage = JSON.parse(reader.result).error;
            setError(errorMessage || 'Failed to export data. Please try again.');
          } catch (parseError) {
            setError('Failed to export data. Please try again.');
          }
        };
        reader.readAsText(err.response.data);
      } else {
        setError('Failed to export data. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="ed-export-data">
      <h2><FontAwesomeIcon icon={faFileExport} /> Export Data</h2>
      <div className="ed-export-form">
        <div className="ed-date-range">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
          />
        </div>
        <div className="ed-data-selection">
          {dataTypes && dataTypes.map((type) => (
            <label key={type.key} className="ed-checkbox-label">
              <input
                type="checkbox"
                checked={selectedData.includes(type.key)}
                onChange={() => {
                  setSelectedData(prev =>
                    prev.includes(type.key)
                      ? prev.filter(t => t !== type.key)
                      : [...prev, type.key]
                  );
                }}
              />
              <span className="ed-checkmark"></span>
              <span>{type.label}</span>
            </label>
          ))}
        </div>
        <div className="ed-export-options">
          <select
            value={exportFormat}
            onChange={(e) => setExportFormat(e.target.value)}
            className="ed-format-select"
          >
            <option value="csv">CSV</option>
            <option value="json">JSON</option>
            <option value="excel">Excel</option>
          </select>
          <button onClick={handleExport} className="ed-export-button" disabled={isLoading}>
            {isLoading ? 'Exporting...' : 'Export'}
          </button>
        </div>
      </div>
      {error && (
        <div className="ed-error-message">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
        </div>
      )}
      {successMessage && (
        <div className="ed-success-message">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default ExportData;
