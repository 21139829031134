import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { fetchAllSensorInfo, addSensorToAccount } from '../../api';
import { ArrowLeft, Plus, Filter, X } from 'react-feather';
import SensorMarkerComponent from './SensorMarkerComponent';
import './MapComponent.css'; 

const MapComponent = () => {
  const [sensorsInfo, setSensorsInfo] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddSensorPopup, setShowAddSensorPopup] = useState(false);
  const [sensorId, setSensorId] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [addSensorError, setAddSensorError] = useState('');
  const [addSensorSuccess, setAddSensorSuccess] = useState('');
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [measurementTypes, setMeasurementTypes] = useState({
    type1: true,
    type2: true,
    type3: true,
  });
  const [sensorStacks, setSensorStacks] = useState({
    stack1: true,
    stack2: true,
    stack3: true,
  });
  const [dataTypes, setDataTypes] = useState({});
  const [sensorModels, setSensorModels] = useState({});
  const [filteredSensors, setFilteredSensors] = useState([]);
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [mapCenter, setMapCenter] = useState([39.8283, -98.5795]); // Center of the US
  const [mapZoom, setMapZoom] = useState(4); // Zoom level for the US view

  useEffect(() => {
    const fetchSensors = async () => {
      try {
        console.log('Fetching sensors...');
        const response = await fetchAllSensorInfo();
        console.log('Fetched sensors:', JSON.stringify(response, null, 2));
        
        if (response && response.sensors) {
          setSensorsInfo(response.sensors);
          setFilteredSensors(response.sensors);
          
          // Initialize dataTypes and sensorModels
          const types = {};
          const models = {};
          response.sensors.forEach(sensor => {
            console.log('Processing sensor:', sensor.sensor_id);
            if (sensor.latest_data) {
              Object.entries(sensor.latest_data).forEach(([key, data]) => {
                if (key !== 'timestamp' && key !== 'gps_location') {
                  types[key] = { checked: true, label: data.label };
                }
              });
            }
            const model = sensor.metadata?.model?.value;
            if (model) {
              models[model] = true;
            }
          });
          setDataTypes(types);
          setSensorModels(models);
        } else {
          console.error('Unexpected response structure:', response);
          setError('Failed to fetch sensor data: Unexpected response structure');
        }
      } catch (error) {
        console.error('Error in fetchSensors:', error);
        setError(`Failed to fetch sensor data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSensors();
  }, []);

  const handleBackToHubs = () => {
    navigate('/hub');
  };

  const handleAddSensor = async (e) => {
    e.preventDefault();
    setAddSensorError('');
    setAddSensorSuccess('');

    try {
      const response = await addSensorToAccount(sensorId, activationCode);
      setAddSensorSuccess(response.message);
      setSensorId('');
      setActivationCode('');
      // Refresh the page after successfully adding a sensor
      window.location.reload();
    } catch (error) {
      setAddSensorError(error.response?.data?.error || 'Failed to add sensor');
    }
  };

  const handleFilterChange = (category, item) => {
    if (category === 'dataTypes') {
      setDataTypes(prev => ({ 
        ...prev, 
        [item]: { ...prev[item], checked: !prev[item].checked }
      }));
    } else if (category === 'sensorModels') {
      setSensorModels(prev => ({ ...prev, [item]: !prev[item] }));
    }
  };

  const handleSelectAll = (category, value) => {
    if (category === 'dataTypes') {
      const newDataTypes = Object.entries(dataTypes).reduce((acc, [key, data]) => {
        acc[key] = { ...data, checked: value };
        return acc;
      }, {});
      setDataTypes(newDataTypes);
    } else if (category === 'sensorModels') {
      const newSensorModels = Object.keys(sensorModels).reduce((acc, key) => {
        acc[key] = value;
        return acc;
      }, {});
      setSensorModels(newSensorModels);
    }
  };

  useEffect(() => {
    const newFilteredSensors = sensorsInfo.filter(sensor => {
      const hasSelectedDataType = Object.keys(sensor.latest_data).some(key => 
        key !== 'timestamp' && key !== 'gps_location' && dataTypes[key]?.checked
      );
      const hasSelectedModel = sensorModels[sensor.metadata.model?.value];
      return hasSelectedDataType && hasSelectedModel;
    });
    setFilteredSensors(newFilteredSensors);
  }, [sensorsInfo, dataTypes, sensorModels]);

  useEffect(() => {
    const container = document.querySelector('.map-component-container');
    if (container) {
      if (showFilterPopup) {
        container.classList.add('filter-open');
        // Force leaflet to update its container size
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 300);
      } else {
        container.classList.remove('filter-open');
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 300);
      }
    }
  }, [showFilterPopup]);

  if (error) {
    return <div style={{ color: 'red', padding: '10px' }}>{error}</div>;
  }

  const FilterPopup = () => (
    <div className={`filter-popup ${showFilterPopup ? 'active' : ''}`}>
      <button onClick={() => setShowFilterPopup(false)} className="filter-close-button">
        <X size={20} />
      </button>
      <h3>Filter Sensors</h3>
      <div className="filter-section">
        <h4>Data Types</h4>
        <div className="select-buttons">
          <button onClick={() => handleSelectAll('dataTypes', true)}>Select All</button>
          <button onClick={() => handleSelectAll('dataTypes', false)}>Deselect All</button>
        </div>
        <div className="filter-grid">
          {Object.entries(dataTypes).map(([type, data]) => (
            <label key={type} className={`filter-chip ${data.checked ? 'selected' : ''}`}>
              <input
                type="checkbox"
                checked={data.checked}
                onChange={() => handleFilterChange('dataTypes', type)}
              />
              <span>{data.label}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="filter-section">
        <h4>Sensor Models</h4>
        <div className="select-buttons">
          <button onClick={() => handleSelectAll('sensorModels', true)}>Select All</button>
          <button onClick={() => handleSelectAll('sensorModels', false)}>Deselect All</button>
        </div>
        <div className="filter-grid">
          {Object.entries(sensorModels).map(([model, checked]) => (
            <label key={model} className={`filter-chip ${checked ? 'selected' : ''}`}>
              <input
                type="checkbox"
                checked={checked}
                onChange={() => handleFilterChange('sensorModels', model)}
              />
              <span>{model}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );

  const toggleMapMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const mapUrl = isDarkMode
    ? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
    : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  const mapAttribution = isDarkMode
    ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

  return (
    <div data-testid="map-component" className="map-component-container">
      <button onClick={handleBackToHubs} className="map-button back-button">
        <ArrowLeft size={20} />
        <span>Back to Hubs</span>
      </button>
      
      <button onClick={() => setShowAddSensorPopup(true)} className="map-button add-sensor-button">
        <Plus size={20} />
        <span>Add Sensor</span>
      </button>

      <button onClick={() => setShowFilterPopup(prev => !prev)} className="map-button filter-button">
        <Filter size={20} />
        <span>Filter</span>
      </button>

      {loading && (
        <div className="loading-overlay">
          <div className="loader"></div>
          <span>Loading Sensors...</span>
        </div>
      )}

      <MapContainer 
        center={mapCenter}
        zoom={mapZoom}
        className="map" 
        style={{height: '100%', width: '100%'}} 
        zoomControl={false}
      >
        <TileLayer
          url={mapUrl}
          attribution={mapAttribution}
        />
        <ZoomControl position="bottomright" />
        {filteredSensors.map(sensor => {
          const lat = sensor.gps_location?.lat || sensor.gps_location?.latitude;
          const lon = sensor.gps_location?.lon || sensor.gps_location?.longitude;
          return (lat && lon) ? (
            <SensorMarkerComponent key={sensor.sensor_id} sensor={sensor} />
          ) : null;
        })}
      </MapContainer>

      <div className="user-info-box">
        Logged in as {localStorage.getItem('username') || 'Guest'}
        <button onClick={toggleMapMode} className="map-mode-toggle">
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>

      {showAddSensorPopup && (
        <div className="popup-overlay">
          <div className="popup add-sensor-popup">
            <button onClick={() => setShowAddSensorPopup(false)} className="close-button">
              <X size={20} />
            </button>
            <h2>Add Sensor</h2>
            <form onSubmit={handleAddSensor}>
              <input
                type="text"
                placeholder="Sensor ID"
                value={sensorId}
                onChange={(e) => setSensorId(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Activation Code"
                value={activationCode}
                onChange={(e) => setActivationCode(e.target.value)}
                required
              />
              {addSensorError && <p className="error-message">{addSensorError}</p>}
              {addSensorSuccess && <p className="success-message">{addSensorSuccess}</p>}
              <div className="button-container">
                <button type="submit" className="submit-button">Add Sensor</button>
                <button type="button" onClick={() => setShowAddSensorPopup(false)} className="cancel-button">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showFilterPopup && <FilterPopup />}
    </div>
  );
};

export default MapComponent;
