import axios from 'axios';
import { BASE_URL, API_USERNAME, API_PASSWORD } from './config';

// Helper function to handle API errors
const handleApiError = (error, customMessage) => {
  console.error(`${customMessage}: `, error);
  throw error;
};

// Helper function to log API calls
const logApiCall = (url) => {
  console.log(`API Call: ${url}`);
};

// Axios instance with default configurations
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to add the JWT token to every request and handle token refresh
api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axios.post(`${BASE_URL}/api/auth/token/refresh/`, { refresh: refreshToken });
        localStorage.setItem('access_token', response.data.access);
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

// Authentication functions

/**
 * Authenticate user and get JWT token
 * @param {string} username - User's username
 * @param {string} password - User's password
 * @returns {Promise<string>} - Access token
 * 
 * Example API call: POST /api/auth/token/
 * Input: { "username": "user", "password": "pass" }
 * Output: { "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ...", "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ..." }
 */
export const authenticate = async (username, password) => {
  try {
    console.log(`Authenticating: POST ${BASE_URL}/accounts/login/`);
    const response = await axios.post(`${BASE_URL}/accounts/login/`, {
      username,
      password,
    });
    if (response.data && response.data.access && response.data.refresh) {
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('username', username);
      return response.data.access;
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error('Unexpected response structure from server');
    }
  } catch (error) {
    console.error('Authentication error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


/**
 * Refresh the access token using the refresh token
 * @returns {Promise<string>} - New access token
 * 
 * Example API call: POST /api/auth/token/refresh/
 * Input: { "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ..." }
 * Output: { "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJ..." }
 */
export const refreshToken = async () => {
  const refresh = localStorage.getItem('refresh_token');
  try {
    const response = await axios.post(`${BASE_URL}/api/auth/token/refresh/`, { refresh });
    const { access } = response.data;
    localStorage.setItem('access_token', access);
    return access;
  } catch (error) {
    console.error('Token refresh error:', error);
    // If refresh fails, remove tokens and redirect to login
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/login';
    throw error;
  }
};


export const fetchAllSensorInfo = async () => {
  try {
    console.log('Fetching all sensors info...');
    const response = await api.get('/sensors/get_all_sensors_info/');
    console.log('All sensors info response:', JSON.stringify(response.data, null, 2));
    return response.data;
  } catch (error) {
    console.error('Error fetching all sensors info:', error);
    console.error('Error details:', error.response ? error.response.data : error.message);
    // Return an empty array instead of throwing an error
    return [];
  }
};

export const fetchSensorInfo = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/get_sensor_info/${sensorId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor info:', error);
    throw error;
  }
};

export const fetch7DayTrend = async (sensorId) => {
  try {
    const response = await api.get(`/sensors/7day_trend/${sensorId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching 7-day trend data:', error);
    throw error;
  }
};

export const fetchSensorMeasurements = async (sensorId, period) => {
  try {
    console.log(`Fetching measurements for sensor ${sensorId} with period ${period}`);
    const response = await api.get(`/sensors/measurements/${sensorId}/${period}/`);
    console.log('Measurements response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching sensor measurements:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export default api;

const obtainToken = async () => {
  try {
    const response = await axios.post(`${BASE_URL}/api/auth/token/`, {
      username: API_USERNAME,
      password: API_PASSWORD,
    });
    const { access, refresh } = response.data;
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
    return access;
  } catch (error) {
    console.error('Error obtaining token:', error);
    throw error;
  }
};

export const addSensorToAccount = async (sensorId, activationCode) => {
  try {
    const response = await api.post('/sensors/add_sensor_to_account/', {
      sensor_id: sensorId,
      activation_code: activationCode,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding sensor to account:', error);
    throw error;
  }
};

export const register = async (username, email, password) => {
  try {
    console.log(`Registering: POST ${BASE_URL}/accounts/register/`);
    const response = await axios.post(`${BASE_URL}/accounts/register/`, {
      username,
      email,
      password,
    });
    if (response.data && response.data.message) {
      console.log('Registration successful:', response.data.message);
      return response.data;
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error('Unexpected response structure from server');
    }
  } catch (error) {
    console.error('Registration error:', error.response ? error.response.data : error.message);
    throw error;
  }
};
