import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faInfoCircle, faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import './NotesSensorInfo.css';

const NotesSensorInfo = ({ sensorData, onSensorUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (sensorData && sensorData.metadata && sensorData.metadata.general_notes) {
      setNotes(sensorData.metadata.general_notes.value?.replace(/\\n/g, '\n') || '');
    }
  }, [sensorData]);

  const handleEditClick = () => {
    setIsEditing(true);
    setError(null);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setNotes(sensorData.metadata.general_notes?.value?.replace(/\\n/g, '\n') || '');
    setError(null);
  };

  const handleSaveNotes = async () => {
    try {
      const response = await api.put(`/sensors/update_notes/${sensorData.sensor_id}/`, { notes: notes.replace(/\n/g, "\\n") });
      onSensorUpdate({
        ...sensorData,
        metadata: {
          ...sensorData.metadata,
          general_notes: { ...sensorData.metadata.general_notes, value: response.data.new_notes }
        }
      });
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update notes');
    }
  };

  if (!sensorData || !sensorData.metadata) {
    return <div>No sensor data available</div>;
  }

  return (
    <div className="notes-sensor-info">
      <div className="notes-section">
        <h2 className="section-title">
          <FontAwesomeIcon icon={faStickyNote} /> Sensor Notes
        </h2>
        <div className="notes-content">
          {isEditing ? (
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter sensor notes"
            />
          ) : (
            <p>{notes}</p>
          )}
        </div>
        <div className="notes-footer">
          {isEditing ? (
            <div className="button-group">
              <button onClick={handleSaveNotes} className="save-button">
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
              <button onClick={handleCancelEdit} className="cancel-button">
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
            </div>
          ) : (
            <button onClick={handleEditClick} className="edit-button">
              <FontAwesomeIcon icon={faEdit} /> Edit Notes
            </button>
          )}
          {error && <div className="error-message">{error}</div>}
        </div>
      </div>
      <div className="sensor-info-section">
        <h2 className="section-title">
          <FontAwesomeIcon icon={faInfoCircle} /> Sensor Information
        </h2>
        <div className="info-grid">
          {Object.entries(sensorData.metadata).map(([key, value]) => (
            key !== 'general_notes' && key !== 'location_notes' && key !== 'shareable_code' && key !== 'is_public' && (
              <div key={key} className="info-item">
                <span className="info-label">{value.label}:</span>
                <span className="info-value">{value.value}</span>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotesSensorInfo;
