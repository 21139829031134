import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { authenticate, register } from '../api';
import './Login.css';

const SensorLogin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const handleLogin = async (loginData) => {
    setIsLoggingIn(true);
    setError('');
    try {
      const token = await authenticate(loginData.username, loginData.password);
      if (token) {
        navigate('/hub');
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.detail || 'An error occurred during login. Please try again.');
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);
    setError('');
    try {
      await register(username, email, password);
      // After successful registration, log the user in
      await handleLogin({ username, password });
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.response?.data?.detail || 'An error occurred during registration. Please try again.');
      setIsLoggingIn(false);
    }
  };

  const handlePublicLogin = () => {
    handleLogin({ username: 'public', password: '64778738' });
  };

  const handlePrivateLogin = (e) => {
    e.preventDefault();
    handleLogin({ username, password });
  };

  return (
    <div className="sl-login-container">
      {isLoggingIn && (
        <div className="sl-loading-overlay">
          <div className="sl-loader"></div>
          <span>Logging in...</span>
        </div>
      )}
      <div className="sl-login-card">
        <motion.div 
          className="sl-login-half sl-public"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2>Public Access</h2>
          <p>Explore sensor data without an account</p>
          <motion.button 
            className="sl-public-button"
            onClick={handlePublicLogin}
            whileHover={{ scale: isLoggingIn ? 1 : 1.05 }}
            whileTap={{ scale: isLoggingIn ? 1 : 0.95 }}
            disabled={isLoggingIn}
          >
            Enter as Guest
          </motion.button>
        </motion.div>
        <motion.div 
          className="sl-login-half sl-private"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="sl-tab-container">
            <button 
              className={`sl-tab ${!isRegistering ? 'active' : ''}`} 
              onClick={() => setIsRegistering(false)}
            >
              Sign In
            </button>
            <button 
              className={`sl-tab ${isRegistering ? 'active' : ''}`} 
              onClick={() => setIsRegistering(true)}
            >
              Sign Up
            </button>
          </div>
          <h2>{isRegistering ? 'Create Account' : 'Welcome Back'}</h2>
          <form className="sl-form" onSubmit={isRegistering ? handleRegister : handlePrivateLogin}>
            <div className="sl-input-group">
              <input
                className="sl-input"
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                disabled={isLoggingIn}
                placeholder=" "
              />
              <label className="sl-label" htmlFor="username">Username</label>
            </div>
            {isRegistering && (
              <div className="sl-input-group">
                <input
                  className="sl-input"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isLoggingIn}
                  placeholder=" "
                />
                <label className="sl-label" htmlFor="email">Email</label>
              </div>
            )}
            <div className="sl-input-group">
              <input
                className="sl-input"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoggingIn}
                placeholder=" "
              />
              <label className="sl-label" htmlFor="password">Password</label>
            </div>
            {error && <p className="sl-error-message">{error}</p>}
            <motion.button 
              className="sl-submit-button"
              type="submit"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={isLoggingIn}
            >
              {isRegistering ? 'Create Account' : 'Sign In'}
            </motion.button>
          </form>
        </motion.div>
      </div>
      
      <motion.div 
        className="sl-note"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.7 }}
      >
        This software is purely a proof-of-concept and is in-progress. Please send feedback to{' '}
        <a href="mailto:christian.bitzas@gmail.com">christian.bitzas@gmail.com</a>. Thank you.
      </motion.div>
    </div>
  );
};

export default SensorLogin;
