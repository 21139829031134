import React, { useState, useEffect } from 'react';
import api from '../../../api';
import './SharingAndAdminContainer.css';

const SharingAndAdminContainer = ({ sensorData, onSensorUpdate }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newUserRole, setNewUserRole] = useState('viewer');
  const [showRemoveSensorPopup, setShowRemoveSensorPopup] = useState(false);
  const [showWipeDataPopup, setShowWipeDataPopup] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  useEffect(() => {
    if (sensorData && sensorData.metadata && sensorData.metadata.is_public) {
      setIsPublic(sensorData.metadata.is_public.value);
    }
  }, [sensorData]);

  const handleTogglePublic = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const newPublicStatus = !isPublic;
      const response = await api.post(`/sensors/set_public_status/${sensorData.sensor_id}/`, {
        is_public: newPublicStatus
      });

      setIsPublic(newPublicStatus);
      if (typeof onSensorUpdate === 'function') {
        onSensorUpdate({
          ...sensorData,
          metadata: {
            ...sensorData.metadata,
            is_public: {
              ...sensorData.metadata.is_public,
              value: newPublicStatus
            }
          }
        });
      }

      console.log(response.data.message);
    } catch (err) {
      console.error('Error toggling sensor public status:', err);
      setError(err.response?.data?.error || 'Failed to update sensor public status');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.post(`/sensors/add_user_to_sensor/${sensorData.sensor_id}/`, {
        username: newUsername,
        role: newUserRole
      });

      console.log(response.data.message);
      setShowAddUserPopup(false);
      setNewUsername('');
      setNewUserRole('viewer');

      // Refresh sensor data to show updated user list
      if (typeof onSensorUpdate === 'function') {
        const updatedSensorData = await api.get(`/sensors/get_sensor_info/${sensorData.sensor_id}/`);
        onSensorUpdate(updatedSensorData.data);
      }
    } catch (err) {
      console.error('Error adding user to sensor:', err);
      setError(err.response?.data?.error || 'Failed to add user to sensor');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveUser = async (username) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.post(`/sensors/remove_user_from_sensor/${sensorData.sensor_id}/`, {
        username: username
      });

      console.log(response.data.message);

      // Refresh sensor data to show updated user list
      if (typeof onSensorUpdate === 'function') {
        const updatedSensorData = await api.get(`/sensors/get_sensor_info/${sensorData.sensor_id}/`);
        onSensorUpdate(updatedSensorData.data);
      }
    } catch (err) {
      console.error('Error removing user from sensor:', err);
      setError(err.response?.data?.error || 'Failed to remove user from sensor');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveSensor = () => {
    if (confirmText === 'REMOVE') {
      // Implement sensor removal logic here
      console.log('Removing sensor from account');
      setShowRemoveSensorPopup(false);
    }
  };

  const handleWipeSensorData = () => {
    if (confirmText === 'DELETE') {
      // Implement data wiping logic here
      console.log('Wiping sensor data');
      setShowWipeDataPopup(false);
    }
  };

  return (
    <div className="sac-sharing-admin-container">
      <div className="sac-sharing-panel">
        <h2 className="sac-heading">Sharing</h2>
        <button onClick={() => setShowAddUserPopup(true)} className="sac-modern-button sac-add-user">Add User</button>
        <div className="sac-user-list">
          {sensorData.users.map((user) => (
            <div key={user.username} className="sac-user-item">
              <span>{user.username} ({user.role})</span>
              {user.role !== 'owner' && (
                <button onClick={() => handleRemoveUser(user.username)} className="sac-modern-button sac-remove-user">Remove</button>
              )}
            </div>
          ))}
        </div>
        <div className="sac-toggle-container">
          <label className="sac-toggle-switch">
            <input 
              type="checkbox" 
              checked={isPublic} 
              onChange={handleTogglePublic}
              disabled={isLoading}
            />
            <span className="sac-slider sac-round"></span>
          </label>
          <span>Make Sensor Public</span>
        </div>
        {error && <p className="sac-error-message">{error}</p>}
      </div>
      <div className="sac-admin-panel">
        <h2 className="sac-heading">Admin Controls</h2>
        <div className="sac-admin-controls">
          <button onClick={() => setShowRemoveSensorPopup(true)} className="sac-modern-button sac-danger">Remove Sensor from Account</button>
          <button onClick={() => setShowWipeDataPopup(true)} className="sac-modern-button sac-danger">Wipe Sensor Data</button>
        </div>
      </div>
      {showAddUserPopup && (
        <div className="sac-popup">
          <div className="sac-popup-content">
            <h3 className="sac-popup-heading">Add User</h3>
            <input
              type="text"
              placeholder="Username"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              className="sac-input"
            />
            <select value={newUserRole} onChange={(e) => setNewUserRole(e.target.value)} className="sac-select">
              <option value="viewer">Viewer</option>
              <option value="admin">Admin</option>
            </select>
            <button onClick={handleAddUser} disabled={isLoading} className="sac-modern-button">Add User</button>
            <button onClick={() => setShowAddUserPopup(false)} className="sac-modern-button sac-secondary">Cancel</button>
          </div>
        </div>
      )}
      {showRemoveSensorPopup && (
        <div className="sac-popup">
          <div className="sac-popup-content">
            <h3 className="sac-popup-heading">Remove Sensor from Account</h3>
            <p>Type REMOVE to confirm:</p>
            <input
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              className="sac-input"
            />
            <button onClick={handleRemoveSensor} disabled={confirmText !== 'REMOVE'} className="sac-modern-button sac-danger">Remove Sensor</button>
            <button onClick={() => setShowRemoveSensorPopup(false)} className="sac-modern-button sac-secondary">Cancel</button>
          </div>
        </div>
      )}
      {showWipeDataPopup && (
        <div className="sac-popup">
          <div className="sac-popup-content">
            <h3 className="sac-popup-heading">Wipe Sensor Data</h3>
            <p>Type DELETE to confirm:</p>
            <input
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              className="sac-input"
            />
            <button onClick={handleWipeSensorData} disabled={confirmText !== 'DELETE'} className="sac-modern-button sac-danger">Wipe Data</button>
            <button onClick={() => setShowWipeDataPopup(false)} className="sac-modern-button sac-secondary">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SharingAndAdminContainer;
