import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import './GeneratePDFReport.css';

const GeneratePDFReport = ({ sensorId }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}${(d.getMonth() + 1).toString().padStart(2, '0')}${d.getFullYear()}`;
  };

  const handleGenerateReport = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      const dateRange = `${formattedStartDate}-${formattedEndDate}`;

      const response = await api.get(`/sensors/generate_pdf_report/${sensorId}/`, {
        params: { date_range: dateRange },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${sensorId}_report_${formattedStartDate}_${formattedEndDate}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSuccessMessage('PDF report generated successfully!');
    } catch (err) {
      console.error('Error in handleGenerateReport:', err);
      if (err.response) {
        if (err.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorMessage = JSON.parse(reader.result).error;
              setError(errorMessage || 'Failed to generate PDF report. Please try again.');
            } catch (parseError) {
              setError('Failed to generate PDF report. Please try again.');
            }
          };
          reader.readAsText(err.response.data);
        } else {
          setError(err.response.data.error || 'Failed to generate PDF report. Please try again.');
        }
      } else {
        setError('Failed to generate PDF report. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="gpr-generate-pdf-report">
      <h2 className="gpr-title"><FontAwesomeIcon icon={faFilePdf} /> Generate PDF Report</h2>
      <div className="gpr-report-form">
        <div className="gpr-date-range">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
            className="gpr-date-input"
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
            className="gpr-date-input"
          />
        </div>
        <button 
          onClick={handleGenerateReport} 
          className={`gpr-generate-button ${isLoading ? 'gpr-loading' : ''}`} 
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin /> Generating...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faFilePdf} /> Generate PDF Report
            </>
          )}
        </button>
      </div>
      {error && (
        <div className="gpr-error-message">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
        </div>
      )}
      {successMessage && (
        <div className="gpr-success-message">
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default GeneratePDFReport;
