import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import loginBg2 from '../assets/sensor-hub-background-image.jpg';
import loginBg1 from '../assets/gps-hub-background-image.jpg';
import './DashboardHub.css';

const DashboardHub = () => {
  const navigate = useNavigate();
  const [activeHub, setActiveHub] = useState(null);
  const [hoveredHub, setHoveredHub] = useState(null);

  const hubs = [
    {
      name: 'Sensor Hub',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 -960 960 960" width="60">
              <path fill="currentColor" d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z"/>
            </svg>`,
      color: '#4CAF50',
      route: '/map',
      description: 'Monitor and analyze sensor data',
      background: loginBg2
    },
    {
      name: 'GPS Hub',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 -960 960 960" width="60">
              <path fill="currentColor" d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/>
            </svg>`,
      color: '#2196F3',
      route: '/gps-map',
      description: 'Track and manage GPS locations',
      background: loginBg1
    },
  ];

  const handleHubClick = (index) => {
    setActiveHub(index);
    setTimeout(() => navigate(hubs[index].route), 500);
  };

  const handleBackToLogin = () => {
    navigate('/');
  };

  return (
    <div className="dh-container">
      <AnimatePresence>
        {hoveredHub !== null ? (
          <motion.div
            key={hoveredHub}
            className="dh-background-image"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ backgroundImage: `url(${hubs[hoveredHub].background})` }}
          />
        ) : (
          <motion.div
            key="default"
            className="dh-background-image dh-background-default"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          />
        )}
      </AnimatePresence>
      <motion.div 
        className="dh-hub-selector"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {hubs.map((hub, index) => (
          <motion.div
            key={index}
            className={`dh-hub-option ${activeHub === index ? 'active' : ''}`}
            style={{ '--hub-color': hub.color }}
            whileHover={{ scale: 1.05, boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)' }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleHubClick(index)}
            onHoverStart={() => setHoveredHub(index)}
            onHoverEnd={() => setHoveredHub(null)}
          >
            <motion.div 
              className="dh-hub-icon"
              initial={{ rotate: 0 }}
              animate={{ rotate: activeHub === index ? 360 : 0 }}
              transition={{ duration: 0.5 }}
            >
              <div dangerouslySetInnerHTML={{ __html: hub.icon }} />
            </motion.div>
            <h2>{hub.name}</h2>
            <p className="dh-hub-description">{hub.description}</p>
          </motion.div>
        ))}
      </motion.div>
      <motion.button
        className="dh-back-button"
        onClick={handleBackToLogin}
        whileHover={{ scale: 1.05, backgroundColor: '#555' }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.5 }}
      >
        Back to Login
      </motion.button>
      
      <motion.div 
        className="dh-note"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.7 }}
      >
        This software is purely a proof-of-concept and is in-progress. Please send feedback to{' '}
        <a href="mailto:christian.bitzas@gmail.com">christian.bitzas@gmail.com</a>. Thank you.
      </motion.div>
    </div>
  );
};

export default DashboardHub;
