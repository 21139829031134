import React, { useState, useCallback, useEffect } from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import './SensorMarkerComponent.css';

// Define icons for active and inactive states
const createCustomIcon = (isActive = false) => {
  const svg = `
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient id="markerGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="0%" style="stop-color:#4CAF50;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#357a38;stop-opacity:1" />
        </radialGradient>
        <filter id="dropShadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2"/>
          <feOffset dx="0" dy="2"/>
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.3"/>
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <linearGradient id="innerCircleGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#ffffff;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#f0f0f0;stop-opacity:1" />
        </linearGradient>
      </defs>
      <circle 
        cx="20" 
        cy="20" 
        r="16"
        fill="url(#markerGradient)"
        filter="url(#dropShadow)"
        style="transform-origin: center; transform: ${isActive ? 'scale(1.1)' : 'scale(1)'}"
      />
      <circle 
        cx="20" 
        cy="20" 
        r="7"
        fill="url(#innerCircleGradient)"
        stroke="rgba(255,255,255,0.8)"
        stroke-width="1"
      />
    </svg>`;

  const blob = new Blob([svg], { type: 'image/svg+xml' });
  const url = URL.createObjectURL(blob);

  return new L.Icon({
    iconUrl: url,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16],
    className: `custom-marker${isActive ? ' active' : ''}`
  });
};

const inactiveIcon = createCustomIcon(false);
const activeIcon = createCustomIcon(true);

// Define styles for the component
const styles = {
  container: {
    padding: '10px',
    textAlign: 'center',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  infoItem: {
    margin: '5px 0',
  },
  label: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: '10px',
    padding: '5px 10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

const SensorMarkerComponent = ({ sensor }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  useEffect(() => {
    console.log('Sensor data:', JSON.stringify(sensor, null, 2));
  }, [sensor]);

  const handleMarkerClick = useCallback(() => {
    setActive(true);
  }, []);

  const formatMeasurement = (data) => {
    if (data.label === 'Wind Direction') {
      return degreesToDirection(data.value);
    }
    return `${typeof data.value === 'number' ? data.value.toFixed(2) : data.value} ${data.unit}`;
  };

  const degreesToDirection = (degrees) => {
    const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    const index = Math.round(((degrees %= 360) < 0 ? degrees + 360 : degrees) / 22.5) % 16;
    return directions[index];
  };

  const getTimeSinceLastUpdate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    const lastUpdate = new Date(timestamp);
    const now = new Date();
    const diff = now - lastUpdate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} Day${days > 1 ? 's' : ''} Ago`;
    if (hours > 0) return `${hours} Hour${hours > 1 ? 's' : ''} Ago`;
    if (minutes > 0) return `${minutes} Minute${minutes > 1 ? 's' : ''} Ago`;
    return `${seconds} Second${seconds !== 1 ? 's' : ''} Ago`;
  };

  const renderSensorInfo = () => {
    if (!sensor) {
      console.error('Sensor data is undefined');
      return <div className="error-message">Sensor data is unavailable</div>;
    }

    const sensorName = sensor.metadata?.name?.value || sensor.sensor_id || 'Unknown Sensor';

    return (
      <>
        <h3>{sensorName}</h3>
        <p className="timestamp">Updated {getTimeSinceLastUpdate(sensor.latest_data_timestamp)}</p>
        <div className="measurements">
          {Object.entries(sensor.latest_data || {}).map(([key, data], index) => {
            if (key !== 'timestamp' && key !== 'gps_location' && data) {
              return (
                <div key={key} className="measurement-item" style={{animationDelay: `${index * 0.1}s`}}>
                  <span className="measurement-label">{data.label}</span>
                  <span className="measurement-value">{formatMeasurement(data)}</span>
                </div>
              );
            }
            return null;
          })}
        </div>
        <button onClick={() => navigate(`/sensor/${sensor.sensor_id}`)}>
          View Data
        </button>
      </>
    );
  };

  const lat = sensor.gps_location?.lat || sensor.gps_location?.latitude;
  const lon = sensor.gps_location?.lon || sensor.gps_location?.longitude;

  if (!sensor || !lat || !lon) {
    console.error('Invalid sensor data or GPS location:', sensor);
    return null;
  }

  return (
    <Marker
      position={[lat, lon]}
      icon={active ? activeIcon : inactiveIcon}
      eventHandlers={{ click: handleMarkerClick }}
    >
      <Tooltip 
        direction="top" 
        offset={[0, -8]} 
        opacity={0.9}
        permanent={false}
        className="sensor-tooltip"
      >
        {sensor.metadata?.name?.value || sensor.sensor_id || 'Unknown Sensor'}
      </Tooltip>
      <Popup className="sensor-popup">
        <div className="sensor-popup-content">
          {renderSensorInfo()}
        </div>
      </Popup>
    </Marker>
  );
};

export default SensorMarkerComponent;
