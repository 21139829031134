import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { getAllGpsTrackers, addGpsTrackerToAccount } from '../../gps-api';
import GPSTrackerMarker from '../../components/gps-components/map-components/GPSTrackerMarker';
import GPSTrackerPanel from '../../components/gps-components/map-components/GPSTrackerPanel';
import { ArrowLeft, Plus, X, Filter } from 'react-feather';
import './GPSMap.css';

const GPSMap = () => {
  const [trackers, setTrackers] = useState([]);
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddTrackerPopup, setShowAddTrackerPopup] = useState(false);
  const [gpsId, setGpsId] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [addTrackerError, setAddTrackerError] = useState('');
  const [addTrackerSuccess, setAddTrackerSuccess] = useState('');
  const navigate = useNavigate();
  const [dataTypeDefinitions, setDataTypeDefinitions] = useState({});
  const mapRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [trackerTypes, setTrackerTypes] = useState({
    animal: true,
    vehicle: true
  });
  const [filteredTrackers, setFilteredTrackers] = useState([]);

  useEffect(() => {
    const fetchTrackers = async () => {
      try {
        const data = await getAllGpsTrackers();
        if (data && Array.isArray(data.trackers)) {
          setTrackers(data.trackers);
          setDataTypeDefinitions(data.data_type_definitions);
        } else {
          setError('Received invalid data from the server');
        }
      } catch (error) {
        setError(error.error || 'Failed to load GPS trackers. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTrackers();
  }, []);

  useEffect(() => {
    if (!trackers || !Array.isArray(trackers)) {
      setFilteredTrackers([]);
      return;
    }

    try {
      const newFilteredTrackers = trackers.filter(tracker => {
        const trackerType = tracker?.tracker_type?.toLowerCase() || '';
        return trackerTypes[trackerType] || false;
      });
      setFilteredTrackers(newFilteredTrackers);
    } catch (error) {
      console.error('Error filtering trackers:', error);
      setFilteredTrackers(trackers);
      setError('Error filtering trackers. Showing all available trackers.');
    }
  }, [trackers, trackerTypes]);

  const handleTrackerClick = (tracker) => {
    setSelectedTracker(tracker);
    if (mapRef.current) {
      const map = mapRef.current;
      const bounds = L.latLngBounds(tracker.last_10_locations.map(loc => [loc.latitude, loc.longitude]));
      
      const currentZoom = map.getZoom();
      const desiredZoom = 15; // Adjust this value as needed
      
      console.log(`Current zoom: ${currentZoom}, Desired zoom: ${desiredZoom}`);
      console.log(`Zooming to bounds: ${JSON.stringify(bounds)}`);
      
      map.flyToBounds(bounds, { 
        padding: [50, 50], 
        maxZoom: desiredZoom,
        duration: 1,
        easeLinearity: 0.5
      });
    }
  };

  const handleClosePanel = () => {
    console.log('Closing panel, not changing zoom');
    setSelectedTracker(null);
  };

  const handleBackToHubs = () => {
    navigate('/hub');
  };

  const handleAddTracker = async (e) => {
    e.preventDefault();
    setAddTrackerError('');
    setAddTrackerSuccess('');

    try {
      console.log(`Calling addGpsTrackerToAccount with gpsId: ${gpsId}, activationCode: ${activationCode}`);
      const response = await addGpsTrackerToAccount(gpsId, activationCode);
      console.log('Response from addGpsTrackerToAccount:', JSON.stringify(response, null, 2));
      
      setAddTrackerSuccess(response.message);
      setGpsId('');
      setActivationCode('');
      
      // Refresh the tracker list
      console.log('Refreshing tracker list...');
      const updatedData = await getAllGpsTrackers();
      console.log('Updated data from getAllGpsTrackers:', JSON.stringify(updatedData, null, 2));
      
      if (updatedData && Array.isArray(updatedData.trackers)) {
        setTrackers(updatedData.trackers);
        console.log('Updated trackers set:', JSON.stringify(updatedData.trackers, null, 2));
      }
    } catch (error) {
      console.error('Error adding GPS tracker:', error);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      setAddTrackerError(error.error || 'Failed to add GPS tracker. Please try again.');
    }
  };

  const toggleMapMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleFilterChange = (type) => {
    setTrackerTypes(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  const handleSelectAll = (value) => {
    setTrackerTypes({
      animal: value,
      vehicle: value
    });
  };

  const mapUrl = isDarkMode
    ? 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
    : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  const mapAttribution = isDarkMode
    ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

  const FilterPopup = () => (
    <div className={`gpsmap-filter-popup ${showFilterPopup ? 'active' : ''}`}>
      <button onClick={() => setShowFilterPopup(false)} className="gpsmap-filter-close-button">
        <X size={20} />
      </button>
      <h3>Filter Trackers</h3>
      <div className="gpsmap-filter-section">
        <h4>Tracker Types</h4>
        <div className="gpsmap-select-buttons">
          <button 
            className="gpsmap-button"
            onClick={() => handleSelectAll(true)}
          >
            Select All
          </button>
          <button 
            className="gpsmap-button"
            onClick={() => handleSelectAll(false)}
          >
            Deselect All
          </button>
        </div>
        <div className="gpsmap-filter-grid">
          <label className={`gpsmap-filter-chip ${trackerTypes.animal ? 'selected' : ''}`}>
            <input
              type="checkbox"
              checked={trackerTypes.animal}
              onChange={() => handleFilterChange('animal')}
            />
            <span>Animal</span>
          </label>
          <label className={`gpsmap-filter-chip ${trackerTypes.vehicle ? 'selected' : ''}`}>
            <input
              type="checkbox"
              checked={trackerTypes.vehicle}
              onChange={() => handleFilterChange('vehicle')}
            />
            <span>Vehicle</span>
          </label>
        </div>
      </div>
    </div>
  );

  return (
    <div className="gpsmap-container">
      <button onClick={handleBackToHubs} className="gpsmap-button gpsmap-back-button">
        <ArrowLeft size={20} />
        <span>Back to Hubs</span>
      </button>

      <button onClick={() => setShowAddTrackerPopup(true)} className="gpsmap-button gpsmap-add-tracker-button">
        <Plus size={20} />
        <span>Add GPS Tracker</span>
      </button>

      <button onClick={() => setShowFilterPopup(prev => !prev)} className="gpsmap-button gpsmap-filter-button">
        <Filter size={20} />
        <span>Filter</span>
      </button>

      {loading && (
        <div className="gpsmap-loading-overlay">
          <div className="gpsmap-loader"></div>
          <span>Loading GPS Trackers...</span>
        </div>
      )}

      {error && (
        <div className="gpsmap-error-message gpsmap-top-message">
          {error}
        </div>
      )}

      {!loading && trackers.length === 0 && !error && (
        <div className="gpsmap-info-message gpsmap-top-message">
          No GPS trackers found. Add a tracker to get started.
        </div>
      )}

      <MapContainer
        ref={mapRef}
        style={{ height: '100%', width: '100%' }}
        zoomControl={false}
        center={[39.8283, -98.5795]} // Center of US
        zoom={4} // Initial zoom level
        whenCreated={(map) => {
          console.log('Map created with initial zoom:', map.getZoom());
          mapRef.current = map;  // Set the ref to the map instance
        }}
      >
        <TileLayer
          url={mapUrl}
          attribution={mapAttribution}
        />
        <ZoomControl position="bottomright" />
        {filteredTrackers.map((tracker) => (
          <GPSTrackerMarker
            key={tracker.gps_id}
            tracker={tracker}
            onClick={handleTrackerClick}
            dataTypeDefinitions={dataTypeDefinitions}
            isSelected={selectedTracker && selectedTracker.gps_id === tracker.gps_id}
          />
        ))}
      </MapContainer>
      <div className="gpsmap-user-info-box">
        Logged in as {localStorage.getItem('username') || 'Guest'}
        <button onClick={toggleMapMode} className="gpsmap-map-mode-toggle">
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>
      {selectedTracker && (
        <GPSTrackerPanel
          tracker={selectedTracker}
          onClose={handleClosePanel}
          dataTypeDefinitions={dataTypeDefinitions}
        />
      )}

      {showAddTrackerPopup && (
        <div className="gpsmap-popup-overlay">
          <div className="gpsmap-popup gpsmap-add-tracker-popup">
            <button onClick={() => setShowAddTrackerPopup(false)} className="gpsmap-close-button">
              <X size={20} />
            </button>
            <h2>Add GPS Tracker</h2>
            <form onSubmit={handleAddTracker}>
              <input
                type="text"
                placeholder="GPS ID"
                value={gpsId}
                onChange={(e) => setGpsId(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Activation Code"
                value={activationCode}
                onChange={(e) => setActivationCode(e.target.value)}
                required
              />
              {addTrackerError && <p className="gpsmap-error-message">{addTrackerError}</p>}
              {addTrackerSuccess && <p className="gpsmap-success-message">{addTrackerSuccess}</p>}
              <div className="gpsmap-button-container">
                <button type="submit" className="gpsmap-submit-button">Add Tracker</button>
                <button type="button" onClick={() => setShowAddTrackerPopup(false)} className="gpsmap-cancel-button">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showFilterPopup && <FilterPopup />}
    </div>
  );
};

export default GPSMap;
