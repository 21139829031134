import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './ChartAndGraphing.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ChartAndGraphing = ({ sensorId, fetchChartData }) => {
  const [chartData, setChartData] = useState(null);
  const [selectedDataTypes, setSelectedDataTypes] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('7_days');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (selectedPeriod !== 'custom') {
      fetchData();
    }
  }, [sensorId, selectedPeriod]);

  const fetchData = async (customPeriod = null) => {
    setLoading(true);
    setError(null);
    try {
      let period = customPeriod || selectedPeriod;
      const data = await fetchChartData(sensorId, period);
      if (!data || Object.keys(data).length === 0) {
        throw new Error('No data available for the selected period.');
      }
      setChartData(data);
      if (selectedDataTypes.length === 0 && data.data_types) {
        const initialDataTypes = Object.keys(data.data_types).filter(key => key !== 'gps_location');
        setSelectedDataTypes(initialDataTypes.length > 0 ? [initialDataTypes[0]] : []);
      }
    } catch (err) {
      console.error('Error fetching chart data:', err);
      setError(err.message || 'An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleDataTypeChange = (dataType) => {
    setSelectedDataTypes(prev => 
      prev.includes(dataType) ? prev.filter(type => type !== dataType) : [...prev, dataType]
    );
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    if (period !== 'custom') {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleCustomDateSubmit = () => {
    if (startDate && endDate) {
      const formatDate = (date) => {
        return date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).split('/').join('');
      };
      const customPeriod = `${formatDate(startDate)}-${formatDate(endDate)}`;
      fetchData(customPeriod);
    } else {
      setError('Please select both start and end dates.');
    }
  };

  const getChartData = () => {
    if (!chartData || !chartData.data_types || !chartData.measurements) return null;

    const datasets = selectedDataTypes
      .filter(dataType => chartData.data_types[dataType]) // Filter out any data types not present in chartData.data_types
      .map((dataType, index) => ({
        label: chartData.data_types[dataType]?.label || dataType, // Use the dataType as fallback if label is not present
        data: chartData.measurements.map(m => m[dataType] || null), // Use null for missing data points
        borderColor: `hsl(${index * 137.5}, 70%, 50%)`,
        backgroundColor: `hsla(${index * 137.5}, 70%, 50%, 0.5)`,
        fill: false,
        tension: 0.4,
        pointRadius: 0,
        borderWidth: 2,
      }));

    if (datasets.length === 0) return null; // Return null if no valid datasets

    return {
      labels: chartData.measurements.map(m => new Date(m.timestamp).toLocaleString()),
      datasets,
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 8,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
  };

  const renderChart = () => {
    if (loading) return <div className="cag-loading">Loading chart data...</div>;
    if (error) return <div className="cag-error">{error}</div>;
    
    const chartDataToRender = getChartData();
    if (!chartDataToRender) return <div className="cag-no-data">No data available for the selected period or data types.</div>;

    return <Line data={chartDataToRender} options={chartOptions} />;
  };

  const CustomDatePickerInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <div className="cag-custom-date-input" onClick={onClick}>
      <input type="text" value={value} readOnly placeholder={placeholder} ref={ref} />
      <FontAwesomeIcon icon={faCalendarAlt} className="cag-calendar-icon" />
    </div>
  ));

  return (
    <div className="cag-chart-and-graphing">
      <div className="cag-controls-column">
        <div className="cag-data-type-selector">
          <h3 className="cag-h3">Data Types</h3>
          <div className="cag-checkbox-container">
            {chartData && chartData.data_types && Object.entries(chartData.data_types).map(([key, value]) => (
              key !== 'gps_location' && (
                <label key={key} className="cag-modern-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedDataTypes.includes(key)}
                    onChange={() => handleDataTypeChange(key)}
                  />
                  <span className="cag-checkmark"></span>
                  {value.label}
                </label>
              )
            ))}
          </div>
        </div>
        <div className="cag-period-selector">
          <h3 className="cag-h3">Time Period</h3>
          <div className="cag-button-group">
            {[
              { value: '1_hours', label: '1H' },
              { value: '1_days', label: '1D' },
              { value: '7_days', label: '7D' },
              { value: '30_days', label: '30D' },
              { value: 'all', label: 'All' },
              { value: 'custom', label: 'Custom' }
            ].map(({ value, label }) => (
              <button
                key={value}
                className={`cag-period-button ${selectedPeriod === value ? 'active' : ''}`}
                onClick={() => handlePeriodChange(value)}
              >
                {label}
              </button>
            ))}
          </div>
          {selectedPeriod === 'custom' && (
            <div className="cag-custom-date-picker">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                customInput={<CustomDatePickerInput />}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                customInput={<CustomDatePickerInput />}
              />
              <button onClick={handleCustomDateSubmit} className="cag-submit-button">Submit</button>
            </div>
          )}
        </div>
      </div>
      <div className="cag-chart-column">
        {renderChart()}
      </div>
    </div>
  );
};

export default ChartAndGraphing;
