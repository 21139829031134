import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardHub from './pages/DashboardHub';
import MapComponent from './components/sensor-components/MapComponent';
import SensorDataPage from './pages/sensor-pages/SensorDataPage';
import SensorLogin from './pages/Login';
import 'leaflet/dist/leaflet.css';
import GPSMap from './pages/gps-pages/GPSMap';

function App() {
  return (
    <Router>
      <div className="App" data-testid="app-component">
        <Routes>
          <Route path="/" element={<SensorLogin />} />
          <Route path="/hub" element={<DashboardHub />} />
          <Route path="/map" element={<MapComponent />} />
          <Route path="/gps-map" element={<GPSMap />} />
          <Route path="/sensor/:sensorId" element={<SensorDataPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
