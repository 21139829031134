import axios from 'axios';
import { BASE_URL, API_USERNAME, API_PASSWORD } from './config';

// Axios instance with default configurations
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to add the JWT token to every request and handle token refresh
api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axios.post(`${BASE_URL}/api/auth/token/refresh/`, { refresh: refreshToken });
        localStorage.setItem('access_token', response.data.access);
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

// Helper function to handle API responses
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'An error occurred');
  }
  return response.json();
};

// User Management API calls
export const addUserToGpsTracker = async (gpsId, username, role) => {
  const response = await fetch(`${BASE_URL}/add_user_to_gps_tracker/${gpsId}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    },
    body: JSON.stringify({ username, role })
  });
  return handleResponse(response);
};

export const removeUserFromGpsTracker = async (gpsId, username) => {
  const response = await fetch(`${BASE_URL}/remove_user_from_gps_tracker/${gpsId}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    },
    body: JSON.stringify({ username })
  });
  return handleResponse(response);
};

// GPS Tracker Management API calls
export const addGpsTrackerToAccount = async (gpsId, activationCode) => {
  try {
    const response = await api.post('/gps/add_gps_tracker_to_account/', {
      gps_id: gpsId,
      activation_code: activationCode
    });
    return response.data;
  } catch (error) {
    console.error('Error adding GPS tracker to account:', error);
    if (error.response) {
      throw error.response.data;
    } else {
      throw { error: 'An unexpected error occurred' };
    }
  }
};

export const removeGpsTrackerFromAccount = async (gpsId) => {
  const response = await fetch(`${BASE_URL}/remove_gps_tracker_from_account/${gpsId}/`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
  });
  return handleResponse(response);
};

// GPS Tracker Data Retrieval API calls
export const getAllGpsTrackers = async () => {
  try {
    const response = await api.get('/gps/get_all_gps_trackers/');
    console.log('API Response:', response.data); // Log the full response
    
    if (response.data && response.data.trackers) {
      return response.data;
    } else if (response.data && response.data.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('Unexpected response format from the server');
    }
  } catch (error) {
    console.error('Error fetching all GPS trackers:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      throw error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      throw new Error('No response received from the server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
      throw error;
    }
  }
};

export const getGpsTrackerInfo = async (gpsId) => {
  const response = await fetch(`${BASE_URL}/gps/get_gps_tracker_info/${gpsId}/`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
  });
  return handleResponse(response);
};

// Authentication function
export const authenticate = async (username, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/token/`, {
      username,
      password,
    });
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);
    return response.data.access;
  } catch (error) {
    console.error('Authentication error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default api;


export const setGpsTrackerPublicStatus = async (gpsId, isPublic) => {
  try {
    const response = await api.post(`/gps/set_public_status/${gpsId}/`, {
      is_public: isPublic
    });
    return response.data;
  } catch (error) {
    console.error('Error setting GPS tracker public status:', error);
    if (error.response) {
      throw error.response.data;
    } else {
      throw { error: 'An unexpected error occurred' };
    }
  }
};
